import React from 'react';
import { View, Text } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import { Container, Header, Content} from 'native-base'

export default class VideosScreen extends React.Component {
    static navigationOptions = {
        drawerLabel: 'Videos',
      }; 
    render() {
       return (<View>
           <Container>
               <Header></Header>
           <Content><Text>Some othe Video content only</Text></Content>
           </Container>
        <Text>   <Icon style={{fontSize: 50}} name="user-circle" />Video Comntent</Text>
       </View>);
    }
}