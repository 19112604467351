import React from "react";
import { StyleSheet } from "react-native";
import {
  View,
  Button
} from "native-base";
import { Modal } from 'react-bootstrap';

class FFPModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      settings: {
        title: props.title, desc: props.desc, buttons: props.buttons || [/*{
          text: "Ok",
          onPress: () => {
            props.okHandler ? props.okHandler() : this.handleClose()
          }
        },*/ {
            text: "Close",
            onPress: () => {
              this.handleClose()
            }
          }]
      },
      visible: props.visible || false
    };
  }

  handleClose() {
    this.props.onRequestClose()
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.visible == true && prevProps.visible != true) {
      this.setState({ visible: true });
    }
    if (nextProps.visible == false && prevProps.visible != false) {
      this.setState({ visible: false });
    }
  }

  onSelect(f) {
    f();
  }
  render() {
    const settings = this.state.settings
    return (
      settings && (
        <View>

          <Modal show={this.state.visible} onHide={this.handleClose} onShow={this.props.onShow} backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{settings.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.children}</Modal.Body>
            <Modal.Footer>
              {settings.buttons.map((button, ind) => (
                <Button key={ind}
                  disabled={button.disabled}
                  onPress={() => this.onSelect(button.onPress)}
                  style={styles.card}
                >
                  {button.text}
                </Button>
              ))}
              {
                this.props.actionButtonConfig && <Button title={this.props.actionButtonConfig.text}
                  disabled={this.props.actionButtonConfig.disabled}
                  onPress={() => this.onSelect(this.props.actionButtonConfig.onPress)}
                  style={styles.card}
                >{this.props.actionButtonConfig.text}</Button>
              }
            </Modal.Footer>
          </Modal>
        </View>
      )
    );
  }
}

export default FFPModal;

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    // justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 10
  },
  cards: {
    minWidth: 300,
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  card: {
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5
  }
});