import React, { useState } from "react";
import { View, Text } from "native-base";
import { ScrollView, SafeAreaView, Dimensions, Platform, ActivityIndicator, RefreshControl, TouchableOpacity, Button, PermissionsAndroid } from 'react-native';
import FFPLabelWithValue from './../common/controls/ffpLabelWithValue'
import FFPLabel from './../common/controls/ffpLabel';
import FFPModal from './../common/Modal/modal'
import FFPAlert from './../common/Alert/alert'
import FFPFileUpload from "../common/FileUpload/FileUpload";
import * as generalHelper from './../common/generalHelper';
import styles from "./../../Styles/commonStyles";
import currPlacementStyles from "./currentPlacementStyles";
import { currentPlacementReduxTypes } from './../../redux/users/currentplacements.redux'
import { fileUploadDownloadReduxTypes } from './../../redux/users/fileUploadDownload.redux'
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/FontAwesome';
import FileViewer from 'react-native-file-viewer';
import { Table, Row, Rows } from 'react-native-table-component';

const ChildBasiInfoComponent = ({ plDetail }) => {
    return (<View >

        <View style={[styles.childInRow, allMarPadBor]}>
            <FFPLabelWithValue labelName="Foster Child Name" labelValue={`${plDetail.firstName} ${plDetail.lastName}`} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
            <FFPLabelWithValue labelName="Gender" labelValue={plDetail.gender} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
            <FFPLabelWithValue labelName="Date of Birth" labelValue={plDetail.fmtDateOfBirth} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
            <FFPLabelWithValue labelName="Beginning of Placement Episode in this home" labelValue={plDetail.fmtPlacementStartDate} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
            <FFPLabelWithValue labelName="Permanency Case Manager" labelValue={plDetail.permanencyCaseManager} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
            <FFPLabelWithValue labelName="Supervisor" labelValue={plDetail.supervisor} wrapperViewStyle={[currPlacementStyles.infoTextWidth, styles.allMargin10]} />
        </View>
    </View>)
}

const TFICurrentPlacementDocumentsComponent = ({ plDetail, downloadResponseDoc }) => {
    const downloadRespDocWrapper = (respDetail, plDetail, respDocDetail) => {
        respDocDetail.isDownloading = true;
        downloadResponseDoc(respDetail, plDetail, respDocDetail.documentName, () => {
            respDocDetail.isDownloading = false;
        })
    }
    return (<View style={Platform.OS == "web" ? {} : { alignItems: "center" }}>
        <FFPLabel labelName="From TFI" viewStyle={{ margin: 'auto', height: 20, backgroundColor: '#b6f3e8', width: '30%', height: 30, justifyContent: 'center' }} textStyle={{ textAlign: 'center' }} />
        {plDetail.responses && plDetail.responses.length ? plDetail.responses.filter(x => x.documents && x.documents.length).map(respDetail =>

            respDetail.allowsMultipleDocuments ? (<View style={[allMarPadBor]}>

                <FFPLabel viewStyle={{ width: "100%" }} labelName={respDetail.documentCategoryDescription} />
                {respDetail.documents.map(respDocDetail =>
                    (<View style={[styles.childInRow, allMarPadBor]} data-test-id="resp-doc">
                        <FFPLabel labelName={respDocDetail.documentName} viewStyle={[{ width: Platform.OS == "web" ? '70%' : '100%' }]}></FFPLabel>

                        <FFPLabel labelName={respDocDetail.fmtUploadTime} viewStyle={{ width: Platform.OS == "web" ? '20%' : '60%' }}></FFPLabel>
                        <TouchableOpacity data-test-id="upload-doc-download-icon" disabled={respDocDetail.isDownloading} onPress={() => downloadRespDocWrapper(respDetail, plDetail, respDocDetail)} style={{ width: Platform.OS == "web" ? '5%' : '10%' }}>
                            <Icon name="download" style={styles.icon} />

                        </TouchableOpacity>
                        <View style={{ width: '5%' }}>{respDocDetail.isDownloading && <ActivityIndicator />}</View>

                    </View>))}

            </View>) : (<View style={[styles.childInRow, allMarPadBor]}>

                <FFPLabel viewStyle={{ width: "70%" }} labelName={respDetail.documentCategoryDescription} />

                <FFPLabel viewStyle={{ width: "20%" }} labelName={respDetail.documents[0].fmtUploadTime} />

                <View style={{ width: '10%' }}>
                    <TouchableOpacity onPress={() => downloadRespDocWrapper(respDetail, plDetail, respDetail.documents[0])}
                        style={[styles.childInRow, { marginBottom: 0, height: 20 }]} disabled={respDetail.documents[0].isDownloading}>
                        <Icon name="download" style={styles.icon} />
                        {respDetail.documents[0].isDownloading && <ActivityIndicator />}
                    </TouchableOpacity>
                </View>
            </View>)
        ) : <View style={{ margin: 'auto' }}><Text>There are no documents from TFI</Text></View>}
    </View>)

}

const CurrentPlacementClientInfoModalComponent = ({ title, headers, formattedData, isModalVisible, onCloseModal }) => {
    return (
        <FFPModal visible={isModalVisible}
            onModalToggle={() => onCloseModal(!isModalVisible)}
            onRequestClose={() => onCloseModal(!isModalVisible)}
            title={<View><Text style={styles.h2b}>{title}</Text></View>}
        >
            <View style={[styles.allMargin10, styles.allPadding10,]}>
                {formattedData && formattedData.length > 0
                    ? (<View><Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
                        {/* <Row data={x.name} style={styles.head} textStyle={styles.text}/> */}
                        {<Row data={headers} textStyle={styles.cpTableHead} />}
                        <Rows data={formattedData} textStyle={styles.text} />
                    </Table></View>)
                    : <View><Text>There is no data under this section</Text></View>}
            </View>
        </FFPModal>
    )
}

const CurrentPlacementUserUploadsComponent = ({ plDetail, deleteUserUploadedDoc, downloadUploadedDoc, downloadDefUploadTypeDoc, openAddUserUploadDocModal }) => {
    const downloadUploadedDocWrapper = (uploadDetail, plDetail, uploadedDocDetail) => {
        uploadedDocDetail.isDownloading = true;
        downloadUploadedDoc(uploadDetail, plDetail, uploadedDocDetail.documentName, () => {
            uploadedDocDetail.isDownloading = false;
        })
    }

    const downloadDefUploadTypeDocWrapper = (uploadDetail, plDetail, uploadedDocDetail) => {
        uploadDetail.defDocDownloading = true;
        downloadDefUploadTypeDoc(uploadDetail, plDetail, () => {
            uploadDetail.defDocDownloading = false;
        })
    }
    const [deleteDocDetail, setDeleteDocDetails] = useState(undefined);
    const [deletePressed, setDeletePressed] = useState(false);
    const [deleteDetail, setDeleteDetail] = useState(undefined);
    return (
        <View data-test-id="clientUploadComponent" style={Platform.OS == "web" ? {} : { alignItems: "center" }}>
            {deletePressed && <FFPAlert title="Delete upload" desc="Are you sure you want to delete this upload?"
                onPositivePress={() => { deleteUserUploadedDoc(deleteDetail, plDetail, deleteDocDetail.documentName); console.log(deleteDocDetail); setDeletePressed(false); }}
                onNegativePress={() => setDeletePressed(false)} />}
            <FFPLabel labelName="From Family" viewStyle={{ margin: 'auto', height: 20, backgroundColor: '#b6f3e8', width: '30%', height: 30, justifyContent: 'center' }} textStyle={{ textAlign: 'center' }} />
            {plDetail.uploads && plDetail.uploads.length ?
                plDetail.uploads.map(uploadDetail =>
                    (
                        <View style={allMarPadBor} data-test-id={uploadDetail.documentCategoryCode}>
                            <View style={[styles.childInRow, { justifyContent: 'center' }]}>
                                <FFPLabel viewStyle={{ justifyContent: 'center' }} textStyle={{ textAlign: 'center', fontWeight: 'bold' }} labelName={uploadDetail.documentCategoryDescription} />
                                {!!uploadDetail.defaultDocumentName && <TouchableOpacity data-test-id="upload-def-doc-download-icon" disabled={uploadDetail.defDocDownloading} onPress={() => downloadDefUploadTypeDocWrapper(uploadDetail, plDetail, uploadDetail)} style={{ width: Platform.OS == "web" ? '5%' : '10%' }}>
                                    <Icon name="download" style={styles.icon} />
                                    {uploadDetail.defDocDownloading && <ActivityIndicator />}
                                </TouchableOpacity>}
                            </View>
                            {uploadDetail.documents && uploadDetail.documents.length ?
                                uploadDetail.documents.map((uploadDocDetail, index) => {
                                    let deletePressed = false;
                                    return (<View key={index} style={[styles.childInRow, allMarPadBor]} data-test-id="upload-doc">
                                        <FFPLabel labelName={uploadDocDetail.documentName} viewStyle={[{ width: Platform.OS == "web" ? '70%' : '100%' }]}></FFPLabel>
                                        <TouchableOpacity data-test-id="upload-doc-download-icon" disabled={uploadDocDetail.isDownloading} onPress={() => downloadUploadedDocWrapper(uploadDetail, plDetail, uploadDocDetail)} style={{ width: Platform.OS == "web" ? '5%' : '10%' }}>
                                            <Icon name="download" style={styles.icon} />
                                            {uploadDocDetail.isDownloading && <ActivityIndicator />}
                                        </TouchableOpacity>
                                        <FFPLabel labelName={uploadDocDetail.fmtUploadTime} viewStyle={{ width: Platform.OS == "web" ? '15%' : '60%' }}></FFPLabel>
                                        <TouchableOpacity data-test-id="upload-doc-delete-icon" onPress={() => {
                                            //deleteDocDetail = uploadDocDetail;
                                            setDeleteDocDetails(uploadDocDetail);
                                            setDeleteDetail(uploadDetail);
                                            setDeletePressed(true)
                                        }} style={{ width: Platform.OS == "web" ? '5%' : '10%' }}>
                                            <Icon name="trash" style={styles.icon} />
                                        </TouchableOpacity>

                                    </View>)
                                }) : <View><Text>There are no documents uploaded in this category.</Text></View>}
                            <TouchableOpacity data-test-id="add-upload-doc" onPress={() => openAddUserUploadDocModal(uploadDetail, plDetail)} style={{ marginBottom: 0, height: 20 }}>
                                <Icon name="plus" style={styles.icon} />
                            </TouchableOpacity>
                        </View>
                    )) : <View style={{ margin: 'auto' }}><Text>There are no documents from TFI</Text></View>}
        </View>
    )
}

const CurrentPlacementClientDatesLink = ({ openModal, title }) => {
    return (
        <View style={currPlacementStyles.modalLinkWrapperView}>
            <TouchableOpacity onPress={() => openModal()} style={{ marginBottom: 0, height: 20 }}>
                <Text style={currPlacementStyles.modalLinkText}>{title}</Text>
            </TouchableOpacity>
        </View>
    )
}

const CurrentPlacementUserUploadModalComponent = (props) => {
    const [selectionErrorMessage, setSelectionErrorMessage] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const fileSelected = (e) => {
        var newFileNamesToAdd = [...e.target.files].map(x => x.name);
        var errorMessages = [];
        if (selectedFiles && selectedFiles.some(x => newFileNamesToAdd.indexOf(x.name) > -1)) {
            errorMessages.push("You are trying to upload a file with the same name. Please rename and upload.")
        }
        if (props.uploadDetail.documents && props.uploadDetail.documents.some(x => newFileNamesToAdd.indexOf(x.documentName) > -1)) {
            errorMessages.push("There is already a file uploaded to server with this name. Please rename and upload.")
        }

        if (errorMessages && errorMessages.length) {
            setSelectionErrorMessage(errorMessages);
            e.preventDefault();
            e.target.value = '';
        }
        else {
            setSelectedFiles([...e.target.files]);
            setSelectionErrorMessage([]);
        }
    }
    const modalClose = () => {
        props.setUploadDocModalVisible(false)
        setSelectedFiles([]);
        setSelectionErrorMessage([])
    }

    const actionButtonConfig = {
        text: "Upload",
        onPress: () => {
            props.uploadUserDocFile(selectedFiles, modalClose, props.uploadDetail, )
        },
        disabled: !selectedFiles ||
            !selectedFiles.length ||
            props.isUploadingUserDoc
    }
    return (
        props.openUploadUserDoc && <FFPModal visible={props.openUploadUserDoc}
            onModalToggle={() => props.setUploadDocModalVisible(!props.openUploadUserDoc)}
            onRequestClose={() => modalClose()}
            actionButtonConfig={actionButtonConfig}
            title={<View><Text style={styles.h2b}>Upload a document</Text></View>}
        >
            <View style={[styles.allMargin10, styles.allPadding10,]}>
                <Text style={{ fontSize: 20 }}>Please enter a title for the document you are uploading: (e.g. "Report Card from 3rd quarter of 2017")</Text>
                <FFPFileUpload type="file" fileChange={(e) => fileSelected(e, props.uploadDetail)} />

                <View>
                    {selectionErrorMessage
                        && selectionErrorMessage.map(msg => (<Text style={{ color: 'red' }}>{msg}</Text>))
                    }
                </View>
                <View>
                    {props.errorUploadingCurrentPlacementDoc &&
                        <Text style={{ color: 'red' }}>
                            {generalHelper.getErrorMessageFromErrorResp(props.errorUploadingCurrentPlacementDoc, 'Error Uploading Document. Please contact administrator.')}
                        </Text>
                    }
                </View>
                <View>
                    {props.isUploadingUserDoc && <ActivityIndicator />}
                    {/* <Button onPress={() => props.uploadUserDocFile(selectedFiles)}
                                        disabled={!selectedFiles ||
                                            !selectedFiles.length ||
                                            props.isUploadingUserDoc} title="Upload"></Button>
                                    <Button onPress={() => props.setUploadDocModalVisible(false)} title="Close"></Button> */}
                </View>
            </View>
        </FFPModal>);
}

const allMarPadBor = [styles.basicBorder, styles.allPadding10, styles.allMargin10]

class CurrentPlacementsDetailScreen extends React.Component {
    constructor(props) {
        super(props);

        var deviceWidth = Dimensions.get("window").width;
        var deviceHeight = Dimensions.get("window").height;
        this.onRefresh = this.onRefresh.bind(this);

        
        this.state = {
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight,
            openUploadUserDoc: false,
            deletePressed: false,
            clientId: this.props.navigation.state.params.clientId
        };

        this.downloadResponse = this.downloadResponse.bind(this);
        this.openEPSDTModal = this.openEPSDTModal.bind(this);
        this.openMedicalVisitsModal = this.openMedicalVisitsModal.bind(this);
        this.openAllergiesListModal = this.openAllergiesListModal.bind(this);
        this.downloadUserUploadedDoc = this.downloadUserUploadedDoc.bind(this);
        this.downloadResponseDoc = this.downloadResponseDoc.bind(this);
        this.openAddUserUploadDocModal = this.openAddUserUploadDocModal.bind(this);
        this.setUploadDocModalVisible = this.setUploadDocModalVisible.bind(this);
        this.uploadUserDocFile = this.uploadUserDocFile.bind(this);
        this.deleteUserUploadedDoc = this.deleteUserUploadedDoc.bind(this);
        this.onCloseEPSDTModal = this.onCloseEPSDTModal.bind(this);
        this.onCloseMedicalVisitsModal = this.onCloseMedicalVisitsModal.bind(this);
        this.onCloseAllergiesListModal = this.onCloseAllergiesListModal.bind(this);
        this.downloadDefUploadTypeDoc = this.downloadDefUploadTypeDoc.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount(){
        if(!this.props.currentPlacements || !this.props.currentPlacements.clients){
            this.props.fetchCurrentPlacementsForFamily({ UserUniqueCode: this.props.userDetails.userUniqueCode });
        }
    }

    downloadResponse(respDetail, plDetail) {

    }

    openEPSDTModal(plDetail) {
        this.setState({ openEPDSTModal: true, plDetail: plDetail });
    }

    onCloseEPSDTModal(plDetail) {
        this.setState({ openEPDSTModal: false, plDetail: undefined });
    }

    openMedicalVisitsModal(plDetail) {
        this.setState({ openMedicalVisitsModal: true, plDetail: plDetail });
    }

    onCloseMedicalVisitsModal(plDetail) {
        this.setState({ openMedicalVisitsModal: false, plDetail: undefined });
    }

    openAllergiesListModal(plDetail) {
        this.setState({ openAllergiesModal: true, plDetail: plDetail });
    }

    onCloseAllergiesListModal(plDetail) {
        this.setState({ openAllergiesModal: false, plDetail: undefined });
    }

    downloadDefUploadTypeDoc(uploadDetail, plDetail, successCallback, failureCallback) {
        if (uploadDetail.defaultDocumentName) {
            var downloadFileRequest = {
                'UploadTypeCode': uploadDetail.documentCategoryCode,
                'FileName': uploadDetail.defaultDocumentName,
                'RequireBase64': Platform.OS != "web"
            };

            var requestConfig = Platform.OS == "web" ? { responseType: 'blob' } : {};
            this.props.downloadDefaultCurrentPlacementClientUploadDocument(downloadFileRequest, requestConfig, (resp) => generalHelper.saveDownloadedFile(resp, uploadDetail.defaultDocumentName, successCallback), () => {
                if (failureCallback) {
                    failureCallback();
                }
            });
        }
    }

    downloadResponseDoc(respDetail, plDetail, fileName, successCallback, failureCallback) {
        this.downloadUserUploadedDoc(respDetail, plDetail, fileName, "RESPONSE", () => {
            if (successCallback) {
                successCallback();
            }
            this.setState({ uploadDetail: respDetail })
        }, () => {
            if (failureCallback) {
                failureCallback();
            }
        });
    }
    downloadUploadedDoc(uploadDetail, plDetail, fileName, successCallback, failureCallback) {
        this.downloadUserUploadedDoc(uploadDetail, plDetail, fileName, "UPLOAD", () => {
            if (successCallback) {
                successCallback();
            }
            this.setState({ uploadDetail: uploadDetail })
        }, () => {
            if (failureCallback) {
                failureCallback();
            }
        });
    }

    // async saveDownloadedFile(resp, fileName, successCallBack) {
    //     if (Platform.OS == "web") {

    //         var blob = new Blob([resp.data], { type: resp.headers["content-type"] }),
    //             url = window.URL.createObjectURL(blob),
    //             a = document.createElement('a')
    //         a.href = url;
    //         a.download = fileName;
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //     }
    //     else {
    //         var RNFetchBlob = require('rn-fetch-blob').default
    //         let dirs = RNFetchBlob["fs"]["dirs"];
    //         let isPermissionGranted = false;
    //         var locationDownloaded = dirs.DownloadDir;
    //         if (Platform.OS == "android") {
    //             const granted = await PermissionsAndroid.request(
    //                 PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    //                 {
    //                     title: 'Foster Family Protal External Storage Permission',
    //                     message:
    //                         'Foster Family Protal needs access to your storage ' +
    //                         'so you can save files.',
    //                     buttonNeutral: 'Ask Me Later',
    //                     buttonNegative: 'Cancel',
    //                     buttonPositive: 'OK',
    //                 },
    //             );
    //             isPermissionGranted = granted === PermissionsAndroid.RESULTS.GRANTED;
    //         }
    //         else {
    //             isPermissionGranted = true;
    //             locationDownloaded = dirs.DocumentDir;
    //         }
    //         if (isPermissionGranted) {
    //             // var base64 = RNFetchBlob.base64;
    //             // var base64Data = RNFetchBlob.base64.encode(resp.data);
    //             RNFetchBlob.fs.writeFile(locationDownloaded + '/' + fileName, resp.data, 'base64')
    //                 .then((downloadPath, arg1, arg2) => {
    //                     FileViewer.open(locationDownloaded + '/' + fileName);
    //                 }, (err) => console.log(err))
    //         }
    //     }

    //     if (successCallBack) {
    //         successCallBack();
    //     }
    // }

    downloadUserUploadedDoc(uploadDetail, plDetail, fileName, fileUploadType, successCallBack, failCallback) {
        var downloadFileRequest = {
            'UserUniqueCode': this.props.userDetails.userUniqueCode,
            'ClientId': plDetail.clientId,
            'DocumentCategoryCode': uploadDetail.documentCategoryCode,
            'FileName': fileName,
            'documentType': fileUploadType,
            'RequireBase64': Platform.OS != "web"
        };

        var requestConfig = Platform.OS == "web" ? { responseType: 'blob' } : {};
        this.props.downloadUserSelectedDoc(downloadFileRequest, requestConfig, (resp) => generalHelper.saveDownloadedFile(resp, fileName, successCallBack), () => {
            if (failCallback) {
                failCallback();
            }
        });
    }

    openAddUserUploadDocModal(uploadDetail, plDetail) {
        this.setState({ uploadErrorMessage: [], selectionErrorMessage: [], userDocUploadInProgress: false, errorDeletingFileOfaRequirement: false, selectedFiles: [], uploadDetail: uploadDetail, plDetail: plDetail })
        this.setUploadDocModalVisible(true);
    }

    setUploadDocModalVisible(isVisible) {
        this.setState({ openUploadUserDoc: isVisible });
    }

    uploadUserDocFile(selectedFiles, successCallBack) {
        let formData = new FormData();
        
        formData.append('file', selectedFiles[0]);

        formData.append('notes', this.state.userUploadDocDesc);
        formData.append('UserUniqueCode', this.props.userDetails.userUniqueCode);
        formData.append('ClientId', this.state.plDetail.clientId);
        formData.append('DocumentCategoryCode', this.state.uploadDetail.documentCategoryCode);

        var requestConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        this.props.uploadUserSelectedDoc(formData, requestConfig, () => {
            const uploadDetail = this.state.uploadDetail;
            if(!uploadDetail.documents) {
                uploadDetail.documents = [];
            }
            uploadDetail.documents.push({ documentName: selectedFiles[0].name, uploadTime: new Date() });
            this.setState({ uploadDetail: uploadDetail, openUploadUserDoc: false });
            successCallBack();
        })
    }

    deleteUserUploadedDoc(uploadDetail, plDetail, fileName) {
        var deleteFileRequest = {
            'notes': this.state.userUploadDocDesc,
            'UserUniqueCode': this.props.userDetails.userUniqueCode,
            'ClientId': plDetail.clientId,
            'DocumentCategoryCode': uploadDetail.documentCategoryCode,
            'FileName': fileName
        };
        console.log(deleteFileRequest)
        this.props.deleteUserDocFromClient(deleteFileRequest, () => {
            uploadDetail.documents = uploadDetail.documents.filter(x => x.documentName != fileName);
            this.setState({ uploadDetail: uploadDetail })
        })
    }

    goBack(){
        this.props.navigation.navigate('Home')
    }

    onRefresh() {
        this.setState({ refreshing: true});
        this.props.fetchCurrentPlacementsForFamily({ UserUniqueCode: this.props.userDetails.userUniqueCode }, () => {
            this.setState({ refreshing: false});
        });
    }

    render() {
        var placementsToDisplay= this.props.currentPlacements && this.props.currentPlacements.clients && this.props.currentPlacements.clients.length ? this.props.currentPlacements.clients.filter(x => x.clientId == this.state.clientId) : [];

        return (
            <SafeAreaView style={Platform.OS == "web" ? { height: "90vh", marginBottom: 0 } : {height: '100%'}}>
            {Platform.OS != "web" && <Button onPress={this.goBack} title="Go Back" />}
                <ScrollView showsVerticalScrollIndicator={false} style={styles.paddingBottom} 
                    refreshControl={
                        <RefreshControl refreshing={this.state.refreshing} onRefresh={this.onRefresh} />
                    }>

                    <FFPLabel textStyle={styles.mainHead} labelName={"ClientId: " + this.state.clientId} />

                    {this.props.isFetchingCurrentPlacements ? <ActivityIndicator /> : (placementsToDisplay && placementsToDisplay.length) ?
                        placementsToDisplay.map(plDetail => (
                            <View style={{ borderWidth: 1, marginBottom: 10 }}>
                                <ChildBasiInfoComponent plDetail={plDetail} />

                                <TFICurrentPlacementDocumentsComponent plDetail={plDetail}
                                    downloadResponseDoc={this.downloadResponseDoc} />

                                <CurrentPlacementClientDatesLink title="EPSDT Dates"
                                    openModal={() => this.openEPSDTModal(plDetail)} />
                                <CurrentPlacementClientDatesLink title="Dates of Medical Visits"
                                    openModal={() => this.openMedicalVisitsModal(plDetail)} />
                                <CurrentPlacementClientDatesLink title="List of Allergies"
                                    openModal={() => this.openAllergiesListModal(plDetail)} />

                                <CurrentPlacementUserUploadsComponent plDetail={plDetail}
                                    deleteUserUploadedDoc={this.deleteUserUploadedDoc}
                                    downloadDefUploadTypeDoc={this.downloadDefUploadTypeDoc}
                                    downloadUploadedDoc={this.downloadUploadedDoc.bind(this)}
                                    openAddUserUploadDocModal={this.openAddUserUploadDocModal}
                                />
                            </View>
                        ))
                        : (<FFPLabel labelName="There are no current placements for this family." />)}
                    <View style={Platform.OS == "web" ? { display: "block" } : {}}>


                        <CurrentPlacementUserUploadModalComponent
                            openUploadUserDoc={this.state.openUploadUserDoc}
                            uploadDetail={this.state.uploadDetail}
                            setUploadDocModalVisible={this.setUploadDocModalVisible}
                            errorUploadingCurrentPlacementDoc={this.state.errorUploadingCurrentPlacementDoc}
                            isUploadingUserDoc={this.props.isUploadingUserDoc}
                            uploadUserDocFile={this.uploadUserDocFile.bind(this)}
                        />
                        {this.state.openEPDSTModal &&
                            <CurrentPlacementClientInfoModalComponent
                                isModalVisible={this.state.openEPDSTModal}
                                title="EPSDT Dates"
                                headers={["Date", "Notes"]}
                                formattedData={this.state.plDetail.epsdtDates && this.state.plDetail.epsdtDates.length ? this.state.plDetail.epsdtDates.map(epdst => [epdst.fmtDate, epdst.name || 'Not Available']) : []}
                                onCloseModal={this.onCloseEPSDTModal} />}

                        {this.state.openMedicalVisitsModal &&
                            <CurrentPlacementClientInfoModalComponent
                                isModalVisible={this.state.openMedicalVisitsModal}
                                title="Medical Visits"
                                headers={["Date", "Notes"]}
                                formattedData={this.state.plDetail.medicalVisits && this.state.plDetail.medicalVisits.length ? this.state.plDetail.medicalVisits.map(visit => [visit.fmtDate, visit.name || 'Not Available']) : []}
                                onCloseModal={this.onCloseMedicalVisitsModal} />}

                        {this.state.openAllergiesModal &&
                            <CurrentPlacementClientInfoModalComponent
                                isModalVisible={this.state.openAllergiesModal}
                                title="Allergies"

                                formattedData={this.state.plDetail.allergies && this.state.plDetail.allergies.length ? this.state.plDetail.allergies.map(allergy => [allergy.name]) : []}
                                onCloseModal={this.onCloseAllergiesListModal} />}
                    </View>
                </ScrollView>

            </SafeAreaView>
        );
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        currentPlacements: currentPlacementReduxTypes.selectors.getCurrentPlacements(state),
        isFetchingCurrentPlacements: currentPlacementReduxTypes.selectors.getIsFetchingCurrentPlacements(state),
        errorFetchingCurrentPlacements: currentPlacementReduxTypes.selectors.getErrorFetchingCurrentPlacements(state),
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),
        //Download State Props
        downloadCurrentPlacementResponse: fileUploadDownloadReduxTypes.selectors.getDownloadCurrentPlacementResponse(state),
        isFetchingCurrentPlacementDoc: fileUploadDownloadReduxTypes.selectors.getIsFetchingCurrentPlacementDoc(state),
        errorFetchingCurrentPlacementDoc: fileUploadDownloadReduxTypes.selectors.getErrorFetchingCurrentPlacementDoc(state),
        //Upload State Props
        uploadUserDocResponse: currentPlacementReduxTypes.selectors.getUploadUserDocResponse(state),
        isUploadingUserDoc: currentPlacementReduxTypes.selectors.getIsUploadingUserDoc(state),
        errorUploadingUserDoc: currentPlacementReduxTypes.selectors.getErrorUploadingUserDoc(state),
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),

    };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
    // Action
    return {
        fetchCurrentPlacementsForFamily: (requestObj, callback) => currentPlacementReduxTypes.actions.fetchCurrentPlacements(dispatch, requestObj, callback),
        uploadUserSelectedDoc: (reqObj, config, callback) => currentPlacementReduxTypes.actions.uploadUserDocAndAssociateToClient(dispatch, reqObj, config, callback),
        downloadUserSelectedDoc: (reqObj, config, successCallback, failureCallback) => fileUploadDownloadReduxTypes.actions.downloadCurrentPlacementDocument(dispatch, reqObj, config, successCallback, failureCallback),
        downloadDefaultCurrentPlacementClientUploadDocument: (reqObj, config, successCallback, failureCallback) => fileUploadDownloadReduxTypes.actions.downloadDefaultCurrentPlacementClientUploadDocument(dispatch, reqObj, config, successCallback, failureCallback),
        deleteUserDocFromClient: (reqObj, successCallback, failureCallback) => currentPlacementReduxTypes.actions.deleteUserDocFromClient(dispatch, reqObj, successCallback, failureCallback),
        resetIsUploadingCurrentPlacementDoc: (val) => fileUploadDownloadReduxTypes.actions.setIsUploadingCurrentPlacementDoc(val),
    };
};

CurrentPlacementsDetailScreen.defaultProps = {
    userDetails: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlacementsDetailScreen)


