import React from 'react'
import {Platform, View} from 'react-native'

const WebHeaderTitle = (props) => {
    return Platform.OS == 'web' ? 
        (<View style={{alignItems: 'center'}}>
            <h4>{props.text}</h4></View>
        ) 
        : null;
}
export default WebHeaderTitle;