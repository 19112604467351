import { createStackNavigator } from 'react-navigation-stack';
import { AuthLoadingScreen, LoginScreen, ForgotPassword } from './features/Authentication'

import DrawerNavigator from './features/OwnDrawer/DrawerNavigator';
import { createTabNavigator, createMaterialTopTabNavigator } from 'react-navigation-tabs';
import { createMaterialBottomTabNavigator } from 'react-navigation-material-bottom-tabs';

import SignupSection from './features/Authentication/Login/SignupSection';
import changePassword from './features/Authentication/ChangePassword/changePassword';
import GuestUserScreen from './features/Home/guestUserScreen';
import CallbackPage from './features/Authentication/oidc/callback';

// const signinStack = createStackNavigator({ SignIn: LoginScreen, ForgotPassword: ForgotPassword }, {headerMode: 'none'});
// const signupStack = createStackNavigator({ SignUp: SignupSection }, {headerMode: 'none'})
// const AuthStack = createMaterialTopTabNavigator({
//   SignIn: {screen: signinStack}, 
//   SignUp: {screen: signupStack}
// }, {headerMode: 'none'})
const signinUpStack = createMaterialTopTabNavigator({
  SignIn: { screen: LoginScreen },
  SignUp: { screen: SignupSection }
}, { headerMode: 'none', swipeEnabled: false });
const AuthStack = createStackNavigator({
  Signin: signinUpStack,
  ChangePassword: { screen: changePassword },
  ForgotPassword: { screen: ForgotPassword },
  Guest: { screen: GuestUserScreen },
  OidcCallback: {screen: CallbackPage}
}, {
  headerMode: 'none',
  initialRouteName: 'Signin'
})


const RoutesConfig = {
  AuthLoading: AuthLoadingScreen,
  Auth: { screen: AuthStack },
  Home: { screen: DrawerNavigator, path: 'app' }
};

export default RoutesConfig;