import React from 'react';
import { TouchableHighlight, Text, Button} from 'react-native'
import { View, Header } from 'native-base';
import t from 'tcomb-form-native'
import styles from '../styles'
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { roleReduxTypes } from "../../../redux/users/role.redux";

// const t = {form: {Form: () => {return "This is Add role"}},
//             maybe: () => {},
//             struct: () => {}
//           };
const Form = t.form.Form;

  // here we are: define your domain model
  var AddRole = t.struct({
    name: t.String, 
    description: t.maybe(t.String),
  });
  
  var options = {}; // optional rendering options (see documentation)
  
  

class AddRoleScreen extends React.Component {

    
    constructor(props) {
      super(props);
  
      this.onAdd = this.onAdd.bind(this);
      this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.navigation.goBack()
    }

    onAdd() {
        // call getValue() to get the values of the form
        var value = this.refs.form.getValue();
        if (value) { // if validation fails, value will be null
          console.log(value); // value here is an instance of Person
          this.props.saveRole(value, (resp) => {
            this.goBack();
          })
        }
      }

    render() {
        return (
            <ScrollView style={styles.container}>
                {/* <Header><Text style={{fontWeight: 'bold'}}>Add Role</Text></Header> */}
                <Button onPress={this.goBack} title="Back">BAck</Button>
          <Form
            ref="form"
            type={AddRole}
            options={options}
          />
          <TouchableHighlight style={styles.button} onPress={this.onAdd} underlayColor='#99d9f4'>
            <Text style={styles.buttonText}>Add</Text>
          </TouchableHighlight>
        </ScrollView>
        )
    }
}



// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    console.log(state)
    // Redux Store --> Component
    return {
      loggedIn: state.authReducer.roleDetails,
    };
  };
  
  // Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
  const mapDispatchToProps = (dispatch) => {
    // Action
      return {
        // Login
        saveRole: (roleDetailsToSave, callback) => roleReduxTypes.actions.saveRole(dispatch, roleDetailsToSave, callback),
     };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleScreen)