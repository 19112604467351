import React from "react";
import { Container, Content, Tabs } from 'native-base';
import VideosScreen from './VideosScreen'
import QuestionsScreen from './QuestionsScreen'
import { Text } from "react-native";

export class FAQTabContainer extends React.Component {
    render() {
        return (
            <React.Fragment>
            
            <Container>
                <Content>
                <Tabs>
                    <Text tabLabel='Questions'><QuestionsScreen /></Text>
                    <Text tabLabel='Videos'><VideosScreen /></Text>
                </Tabs>
                </Content>
            </Container>
            
            </React.Fragment>
          );
    }
}