import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { push } from "react-router-redux";
import userManager from "./userManager";

class CallbackPage extends React.Component {
  successCallback() {
    alert("successCallback")
    this.props.navigation.navigate('Signin');
    //this.props.dispatch(push("/"));
  }

  render() {
    // just redirect to '/' in both cases
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => { alert(); this.successCallback();}
        }
        errorCallback={error => {
          //this.props.dispatch(push("/"));
          console.error(error);
        }}
        >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
