import React from 'react'
import { Text, View, Dimensions, Image } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import './../../../main.css'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import * as AsyncStorage from '../AsyncStorage/storageHelper'
import { NavigationActions } from 'react-navigation'
import { connect } from 'react-redux';
import { userAuthReduxTypes } from './../../../redux/users/auth.redux'
import FFPAlert from '../Alert/alert'
import * as generalHelper from './../generalHelper'

class AppNavHeader extends React.Component {
    constructor(props) {
        super(props);
        let deviceWidth = Dimensions.get("window").width;
        let deviceHeight = Dimensions.get("window").height;

        this.signoutClick = this.signoutClick.bind(this);
        this.successLogoutClick = this.successLogoutClick.bind(this);
        this.cancelLogoutClick = this.cancelLogoutClick.bind(this);

        this.state = {
            userDetails: {},
            signoutClicked: false,
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight,
            browserBasedStyle: generalHelper.isSafari() ? {width: '95vw', paddingLeft: '10vw', paddingRight: '5vw'} : {width: deviceWidth}
        }


    }

    componentDidUpdate(prevProps, newProps) {
        //console.log(newProps)
        // if(newProps.userDetails.userId && (!prevProps || 
        //         !prevProps.userDetails|| 
        //         !prevProps.userDetails.userId || 
        //         prevProps.userDetails.userId !== newProps.userDetails.userId)) {
        //     this.setState( {userDetails: newProps.userDetails});
        // }
    }

    componentDidMount() {

        AsyncStorage.getItem('loggedInUserDetails').then((userDetails) => {
            try {
                if (userDetails) {
                    this.setState({ userDetails: JSON.parse(userDetails) });
                }
                else {
                    if (window.location.indexOf('AuthLoading') === -1)
                        window.location = '/app/AuthLoading';
                }
            }
            catch {
                console.error("error checking for Logged in details")
            }

        })
    }

    async successLogoutClick() {
        await AsyncStorage.clear();
        window.location = '/app/Auth';
        this.setState({ signoutClicked: false });
    }

    cancelLogoutClick() {
        this.setState({ signoutClicked: false });
    }

    signoutClick() {
        this.setState({ signoutClicked: true });
    }

    changeUrl(event, routeName, subRouteName) {
        event.stopPropagation();
        event.preventDefault();
        if (subRouteName) {
            this.props.navigation.navigate(routeName, {},
                NavigationActions.navigate({
                    routeName: subRouteName
                }));
        }
        else {

            this.props.navigation.navigate(routeName);
            window.location.reload(true);

        }
    }

    render() {

        if (this.state.userDetails && this.state.userDetails.userId) {
            if (this.state.userDetails.isGuestUser) {
                return (<Navbar bg="light" expand="lg" style={{ justifyContent: 'space-between' }}>
                    <Navbar.Brand href="/app">Foster Family Portal</Navbar.Brand>

                    <Nav.Link href="/app/Home/Guest">Home</Nav.Link>
                    <NavDropdown
                        className="lowPaddingDropdown"

                        title={
                            <View style={{ display: 'inline-block', paddingLeft: '5px' }}>
                                <Icon glyph="star" /> Hello {this.state.userDetails ? this.state.userDetails.firstName : ''}
                            </View>
                        }
                        id="basic-nav-dropdown"
                    >
                        <NavDropdown.Item href="/app/Profile">Profile</NavDropdown.Item>
                        <NavDropdown.Item href="/app/ChangePassword">Change Password</NavDropdown.Item>
                        <NavDropdown.Item onClick={this.signoutClick}>Sign out</NavDropdown.Item>
                        {this.state.signoutClicked &&
                            <FFPAlert title="Logout" desc="Are you Sure?"
                                onPositivePress={this.successLogoutClick}
                                onNegativePress={this.cancelLogoutClick}
                            />}
                    </NavDropdown>
                </Navbar>);
            }
            else {
                return (
                    <Navbar bg="light" expand="lg" style={this.state.browserBasedStyle}>
                        <Navbar.Brand href="/app">
                            <View style={{ flexDirection: "row" }}>
                                <Image source={require('../assets/thumbnail.png')} style={{ width: 50, height: 50 }} />
                                <View style={{ alignItems: 'center', justifyContent: 'center', paddingLeft: 5 }}>
                                    <Text style={{ fontSize: 12, color: 'teal' }}>TFI Foster Family Portal</Text>
                                    {console.log(this.state.userDetails)}
                                    <Text style={{ fontSize: 19, color: 'teal' }}> {!this.state.userDetails ? "Kansas" : this.state.userDetails.stateCode == "KS" ? "Kansas" :
                                        this.state.userDetails.stateCode == "NE" ? "Nebraska" :
                                            this.state.userDetails.stateCode == "OK" ? "Oklahoma" :
                                                "Texas"}</Text>
                                </View>
                            </View>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto justify-content-between" style={{ width: "100%" }}>
                                <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'Notifications')}  >Notifications</Nav.Link>
                                {/* <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'LicensingO')}>Licensing & Renewal</Nav.Link> */}
                                <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'Licensing')}>Licensing & Renewal</Nav.Link>
                                <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'CurrentPlacements')}>Current Placements</Nav.Link>
                                <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'YourMatches')}>Your Matches</Nav.Link>
                                <Nav.Link href="#"
                                    onClick={(e) => this.changeUrl(e, 'FosterParentInfo')}>Foster Family Info</Nav.Link>

                            </Nav>

                        </Navbar.Collapse>

                        <NavDropdown
                            title={this.state.userDetails ? this.state.userDetails.firstName + " " + this.state.userDetails.lastName : ''}

                            style={{ paddingHorizontal: 0, marginHorizontal: 0 }}
                        >
                            <NavDropdown.Item href="#" onClick={(e) => this.changeUrl(e, 'Profile')}>
                                Profile
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#" onClick={(e) => this.changeUrl(e, 'ChangePassword')}>Change Password</NavDropdown.Item>
                            <NavDropdown.Item onClick={this.signoutClick}>Sign out</NavDropdown.Item>
                            {this.state.signoutClicked && <FFPAlert title="Logout" desc="Are you Sure?"
                                onPositivePress={this.successLogoutClick}
                                onNegativePress={this.cancelLogoutClick}
                            />}

                        </NavDropdown>
                    </Navbar >
                );
            }
        }
        else {
            // return (<View><Text>Not logged in so no header.</Text></View>);
            return (<Navbar bg="light" expand="lg" style={{ width: '100vw' }}>
                <Navbar.Brand href="/app">Foster Family Portal</Navbar.Brand>
            </Navbar>)
        }
    }

}

const mapStateToProps = (state) => {
    return {
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state),
    };
};

export default connect(mapStateToProps)(AppNavHeader);