import  React from 'react'
import {ActivityIndicator} from 'react-native'
// import {Overlay} from 'react-native-elements'
import { View, Text, List, ListItem } from 'native-base'
import { NavigationActions } from 'react-navigation';
import { ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as apiHelper from "../../common/apiHelper";
import {connect} from 'react-redux'
import {roleReduxTypes} from './../../../redux/users/role.redux'
import FFPAlert from '../../common/Alert/alert';

class RoleListScreen extends React.Component {
    constructor(props) {
        super(props);

        this.addNewRole = this.addNewRole.bind(this);
        this.editRole = this.editRole.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        this.deleteClickConfirm = this.deleteClickConfirm.bind(this);
        this.cancelDeleteClick = this.cancelDeleteClick.bind(this);

        this.state = {rolesList : [], fetchingRolesList: true, deleteRoleClicked: false, deleteRoleDetails: undefined}

        
    }
    componentDidMount() {
      this.props.getAllRoles((resp) => {
        console.log(resp)
      });
    
    }

    componentWillUnmount() {
      this.setState({deleteRoleClicked: false, deleteRoleDetails: undefined})
    }

    addNewRole() {
        this.props.navigation.navigate('Add')
    }

    cancelDeleteClick() {
      this.setState({deleteRoleClicked: false, deleteRoleDetails: undefined})
    }

    deleteClickConfirm() {

      this.props.deleteRole(this.state.deleteRoleDetails, () => {
        console.log('delete successful');
        this.setState({deleteRoleClicked: false, deleteRoleDetails: undefined})
        this.refreshList()
      });
    }

    refreshList() {
      this.props.getAllRoles((resp) => {
        console.log(resp)
      });
    
    }

    deleteRole(role) {
        // this.props.navigation.navigate('Delete', {role: role})
        this.setState({deleteRoleClicked: true, deleteRoleDetails: role});
    }

    editRole(role) {
        this.props.navigation.navigate({routeName: 'Edit', params: {role}})
    }

    render() {
      // console.log(this.props.rawState)
        return (
          <View>
            <ScrollView>
            {this.props.isDeleteRoleInProgress === true && <View style={{
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  }}><ActivityIndicator size='large' /></View>}
              {this.state.deleteRoleClicked && <FFPAlert title="Delete Role" desc="Are you Sure?" 
                    onPositivePress={this.deleteClickConfirm} 
                    onNegativePress={this.cancelDeleteClick} 
                />}
                <Text>Roles List</Text>
                <Icon name="plus" size={40} style={{textAlign: 'right'}}
                    onPress={this.addNewRole} />
                    
            <List>
            
        <ActivityIndicator animating={this.props.isFetchingAllRoles} size="large" color="#0000ff" /> 
        <Icon name="refresh" size={40} style={{textAlign: 'right'}}
                    onPress={this.refreshList} />
                {this.props.allRoles && 
                this.props.allRoles.map((role, ind) => {
                    return ( <ListItem key = {ind}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>{role.firstName + ' ' + role.lastName}</Text>
                        <Icon name="pencil" size={30} style={{height: 30, width: 30}} 
                            onPress={() => this.editRole(role)} />
                        <Icon name="trash" size={30} style={{height: 30, width: 30}}
                            onPress={() => this.deleteRole(role)} />
                        </View>
                      </ListItem>)
                })}
          </List>
            </ScrollView>
            </View>
        )
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  // Redux Store --> Component
  return {
    allRoles: roleReduxTypes.selectors.getAllRoles(state),
    // rawState: state,
    isFetchingAllRoles: roleReduxTypes.selectors.getIsFetchingAllRoles(state),
    errorFetchingAllRoles: roleReduxTypes.selectors.getErrorFetchingAllRoles(state),
    isDeleteRoleInProgress: roleReduxTypes.selectors.getIsDeleteRoleInProgress(state),
    errorDeletingRole: roleReduxTypes.selectors.getErrorDeletingRole(state),
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
    return {
      // Login
      getAllRoles: (callback) => roleReduxTypes.actions.fetchAllRoles(dispatch, {}, callback),
      deleteRole: (roleDetails, callback) => roleReduxTypes.actions.deleteRole(dispatch, roleDetails, callback),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleListScreen)