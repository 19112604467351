import React from 'react';
import { View, Dimensions, SafeAreaView, Platform, TouchableOpacity, Text, Image } from 'react-native';
import {
    createDrawerNavigator,
    DrawerNavigatorItems,
} from 'react-navigation-drawer';
import { createStackNavigator } from "react-navigation-stack";
import Icon from 'react-native-vector-icons/FontAwesome'
import AppNavHeader from '../common/Header/header'
import MainDrawer from '../OwnDrawer/MainDrawer'
import { QuestionsScreen, VideosScreen } from '../FAQ';
import { createMaterialBottomTabNavigator } from 'react-navigation-material-bottom-tabs';
import { OtherScreen } from '../common';
import { AuthLoadingScreen, SignoutScreen } from '../Authentication';
import {
    UserListScreen, EditUserScreen, AddUserScreen, DeleteUserScreen,
    RoleListScreen, AddRoleScreen, EditRoleScreen
} from '../Admin';

import { NotificationScreen } from '../Notification';
import { createMaterialTopTabNavigator } from 'react-navigation-tabs';
import { HomeScreen } from '../Home';
import { useSelector } from 'react-redux'
import LicensingScreen from '../Licensing/licensingScreen'
import LicensingScreenRedesign from '../Licensing/licensingScreenRedesign'
import changePassword from '../Authentication/ChangePassword/changePassword';
import GuestUserScreen from '../Home/guestUserScreen';
import RenewalScreen from '../Renewal/renewalScreen';
import YourMatchesScreen from "../YourMatches/yourMatchesScreen";
import FosterParentInfoScreen from "../FosterParentInfo/fosterParentInfoScreen";
import CurrentPlacementsDetailScreen from "../CurrentPlacements/currentPlacementsDetailScreen";
import CurrentPlacementsHomeScreen from "../CurrentPlacements/currentPlacementsHomeScreen";

const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;
//let userState = undefined;
const stackConfig = Platform.select({
    web: { header: 'none' },
    default: {},

})

const headerTextFontSize = Platform.select({ ios: 25, android: 0.045 * Dimensions.get('window').width });
const stateTextFontSize = Platform.select({ ios: 15, android: 10 });
const titleTextFontSize = Platform.select({ ios: 25, android: 18 });
const headerPadding = Platform.select({ ios: 20, android: 2 });
const navHeaderStyle = {
    mainView: { paddingTop: headerPadding, flexDirection: 'row' },
    stateHeadingText: { fontSize: headerTextFontSize, color: 'teal', paddingRight: 10 },
    titleHeadingText: { fontSize: headerTextFontSize, color: 'teal' }
}
const NativeHeader = ({ title, navigation }) => {
    const userState = useSelector(state => state);
    return (<View style={navHeaderStyle.mainView}>
        <AppNavHeader navigation={navigation} />
        <View style={{ flexDirection: 'column', width: '70%', alignContent: 'center', alignItems: 'center' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={navHeaderStyle.titleHeadingText}>TFI Foster Family Portal</Text>

            </View>
            {<Text style={{ fontSize: titleTextFontSize, paddingBottom: 10 }}>{title}</Text>}
        </View>
        <View style={{ flexDirection: 'column' }}>
            <Image source={require('../common/assets/thumbnail.png')
            } style={{ width: 50, height: 50, paddingLeft: 10 }}></Image>
            <Text style={[{ fontSize: stateTextFontSize, paddingRight: 5 }]}>
                {userState.authReducer.authDetails.stateCode == "KS" ? "Kansas" :
                    userState.authReducer.authDetails.stateCode == "NE" ? "Nebraska" :
                        userState.authReducer.authDetails.stateCode == "OK" ? "Oklahoma" :
                            "Texas"}
            </Text>
        </View>
    </View>);
}
export const StateBanner = () => {
    const userState = useSelector(state => state);

    return <View style={{ flexDirection: 'row', paddingRight: 2 }}>
        <View style={{ alignItems: 'center', justifyContent: 'center', paddingRight: 2 }}>

            <Text style={{ fontSize: 10, color: 'teal' }}>TFI Foster Family Portal</Text>
            <Text style={{ fontSize: 19, color: 'teal' }}>
                {userState.authReducer.authDetails.stateCode == "KS" ? "Kansas" :
                    userState.authReducer.authDetails.stateCode == "NE" ? "Nebraska" :
                        userState.authReducer.authDetails.stateCode == "OK" ? "Oklahoma" :
                            "Texas"}
            </Text>
        </View>
        <Image source={require('../common/assets/thumbnail.png')
        } style={{ width: 50, height: 50 }}></Image>

    </View>
}
const customContentComponent = props => (
    <SafeAreaView
        style={{ flex: 1, height: WINDOW_HEIGHT, backgroundColor: '#43484d' }}
        forceInset={{ top: 'always', horizontal: 'never' }}>
        <View style={{ marginLeft: 10 }}>
            <DrawerNavigatorItems {...props} />
            <SignoutScreen style={{ justifyContent: 'flex-start' }} />
        </View>
    </SafeAreaView>
);

const FAQMatBottomTabNav = createMaterialBottomTabNavigator({
    Questions: { screen: QuestionsScreen },
    Videos: { screen: VideosScreen }
},
    {}
);


const authUsersHome = createMaterialTopTabNavigator({
    LicensingAndRenewal: {
        screen: HomeScreen,
        navigationOptions: {
            title: "LICENSING",
            tabBarIcon: () => <View style={{ flex: 1 }}><Icon size={20} name='id-card-o' /></View>,
        },
    },
    CurrentPlacements: {
        screen: CurrentPlacementsHomeScreen,
        navigationOptions: {
            tabBarIcon: () => <View style={{ flex: 1 }}><Icon size={20} name='child' /></View>
        }
    },
    YourMatches: {
        screen: YourMatchesScreen,
        navigationOptions: {
            title: "Matches",
            tabBarIcon: () => <View style={{ flex: 1 }}><Icon size={20} name='handshake-o' /></View>
        }
    },
    FosterParentInfo: {
        screen: FosterParentInfoScreen,
        navigationOptions: {
            title: "Parent Info",
            tabBarIcon: () => <View style={{ flex: 1 }}><Icon size={20} name='emoticon-cool-outline' /></View>
        }
    }
}, {

    // tabBarComponent: ({ tintcolor }) => (
    //   <Icon name="user-circle" size={24} />
    // ),
    tabBarOptions: {
        showIcon: true,
        // showLabel : false
    },
    defaultNavigationOptions: {
        // tabBarOnPress: (scene, jumpToIndex) => {

        // },
    }
});

//const userState = useSelector(state => state.counter)
//console.log(userState)
const defaultNavOptions = (title) => {
    //
    //getUserState()
    //let userState = getState();
    //console.log(userState)
    if (Platform.OS == "web") {
        return ({ navigation }) => ({
            // headermode: 'none'
            headerLeft: (<AppNavHeader navigation={navigation} />),

        })
    }
    else {
        return ({ navigation }) => ({
            title: title,
            header: (<NativeHeader title={title} navigation={navigation} />),
            drawerLockMode: 'locked-closed',
            //headerLeft: (<AppNavHeader navigation={navigation} />),
            //headerRight: (
            //  <View style={{}}>
            //    <StateBanner />
            //  </View>
            //)
        })
    }
}

const profileStack = createStackNavigator(
    {
        Profile: {
            screen: FAQMatBottomTabNav,
            navigationOptions: defaultNavOptions('Profile'),
        },
    }, stackConfig);

const changePasswordStack = createStackNavigator(
    {
        changePassword: {
            screen: changePassword,
            navigationOptions: defaultNavOptions('ChangePassword'),
        }
    }, stackConfig);

const faqStack = createStackNavigator(
    {
        FAQ: {
            screen: FAQMatBottomTabNav,
            navigationOptions: defaultNavOptions('Questions')
        },
    }, stackConfig);

const userActionsStack = createStackNavigator({
    List: { screen: UserListScreen, navigationOptions: defaultNavOptions('Users List') },
    Add: { screen: AddUserScreen, navigationOptions: defaultNavOptions('Add User') },
    Edit: { screen: EditUserScreen, navigationOptions: defaultNavOptions('Edit User') },
    Delete: { screen: DeleteUserScreen, navigationOptions: defaultNavOptions('Admin -> Users') }
}, stackConfig);

/*const roleActionsStack = createStackNavigator({
  List: { screen: RoleListScreen, navigationOptions: defaultNavOptions('Roles List') },
  Add: { screen: AddRoleScreen, navigationOptions: defaultNavOptions('Add Role') },
  Edit: { screen: EditRoleScreen, navigationOptions: defaultNavOptions('Edit Role') }
}, stackConfig);*/

// const adminStack = createStackNavigator(
//     {Admin_Users: {
//         screen: userActionsStack,
//         // navigationOptions: defaultNavOptions('Admin -> Users')
//         },
//       Admin_Roles: {
//         screen: roleActionsStack
//       }
//     }, {headerMode: 'none'});

const notificationStack = createStackNavigator(
    {
        Notification: {
            screen: NotificationScreen,
            path: 'Notifications',
            navigationOptions: defaultNavOptions('Notifications')
        },
    }, stackConfig);
// const licensingStack = createStackNavigator(
//     {
//         Licensing: {
//             screen: LicensingScreen,
//             path: 'LicensingR',
//             navigationOptions: defaultNavOptions('Licensing & Renewal')
//         },
//     }, stackConfig);
const licensingRedesignStack = createStackNavigator(
    {
        Licensing: {
            screen: LicensingScreenRedesign,
            path: 'Licensing',
            navigationOptions: defaultNavOptions('Licensing & Renewal')
        },
    }, stackConfig);
// const currentPlacementsStack = createStackNavigator(
//   {
//     Placements: {
//       screen: CurrentPlacementsScreen,
//       navigationOptions: defaultNavOptions('Current Placements')
//     },
//   }, stackConfig);


var licensingStackConfig = {
    initialRouteName: "Home"
}
const currentPlacementsStack = createStackNavigator(
    {
        Home: {
            screen: CurrentPlacementsHomeScreen,
            navigationOptions: defaultNavOptions('Current Placements'),
        },
        Detail: {
            screen: CurrentPlacementsDetailScreen,
            navigationOptions: defaultNavOptions('Current Placements'),
        },
    }, { ...stackConfig, licensingStackConfig });

const fosterParentInfoStack = createStackNavigator(
    {
        ParentInfo: {
            screen: FosterParentInfoScreen,
            navigationOptions: defaultNavOptions('Foster Parent Info')
        },
    }, stackConfig);

const yourMatchesStack = createStackNavigator(
    {
        YourMatches: {
            screen: YourMatchesScreen,
            navigationOptions: defaultNavOptions('Your Matches')
        },
    }, stackConfig);

const homeStack = createStackNavigator(
    {
        Home: {
            screen: authUsersHome,
            navigationOptions: defaultNavOptions('Home')
        },
        Guest: { screen: GuestUserScreen },
        navigationOptions: defaultNavOptions('Home')
    }, stackConfig);

const drawerNavigatorConfig = {
    initialRouteName: 'Notifications',
    //swipeEnabled: false,
    //drawerLockMode: 'locked-open',
    //gestureEnabled: false,
    contentOptions: {
        unmountOnBlur: true,
        activeTintColor: 'blue',
        activeBackgroundColor: 'blue',
        inactiveTintColor: '#ffffff',
        inactiveBackgroundColor: 'transparent',
        backgroundColor: '#008080',
        drawerPosition: 'right',
        // headerLeft: (),
        labelStyle: {
            fontSize: 15,
            marginLeft: 0,
        },
    },
    //The drawer is integral to the navigation of the app, but has no use on web due to the header.
    //Setting the drawerWidth to 0 if it's on web makes the drawer inactive on web.
    drawerWidth: Math.min(WINDOW_WIDTH * (Platform.OS == "web" ? 0 : 0.6), 300),


}

if (Platform.OS !== 'web') {
    // drawerConfig.contentComponent= customContentComponent
    drawerNavigatorConfig.contentComponent = MainDrawer
}
else {
    //drawerNavigatorConfig.contentComponent = MainDrawer;
}

const evaluateChildDrawerTitle = ({ navigation }) => ({
    title: navigation.state.key.substr(navigation.state.key.indexOf('_') + 1),
});

const DrawerNavigator = createDrawerNavigator(
    {
        //userState = useSelector(state => state.counter)
        // Home: homeStack,
        // FAQ: faqStack,
        Notifications: notificationStack,
        // LicensingO: licensingStack,
        Licensing: licensingRedesignStack,
        CurrentPlacements: currentPlacementsStack,
        YourMatches: yourMatchesStack,
        FosterParentInfo: fosterParentInfoStack
        //Admin_Users: { screen: userActionsStack, path: 'Admin/Users', navigationOptions: evaluateChildDrawerTitle },
        //"Admin_Roles": { screen: roleActionsStack, path: 'Admin/Roles', navigationOptions: evaluateChildDrawerTitle },
        // Signout: signoutStack,
        // Videos: {
        // //   path: 'FAQ/Videos',
        //   screen: videoStack,
        // },
    },
    drawerNavigatorConfig,
);

export default DrawerNavigator;
