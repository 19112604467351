// Imports: Dependencies
import { combineReducers } from 'redux';

import {userAuthReduxTypes} from '../users/auth.redux';
import {userReduxTypes} from '../users/user.redux';
import {roleReduxTypes} from '../users/role.redux';
import {licensingReduxTypes} from '../licensing/licensing.redux';
import {fosterFamilyInfoReduxTypes} from '../users/fosterfamilyinfo.redux';
import {currentPlacementReduxTypes} from '../users/currentplacements.redux';
import {fileUploadDownloadReduxTypes} from '../users/fileUploadDownload.redux';
import { routerReducer } from 'react-router-redux';
import { reducer as oidcReducer } from 'redux-oidc';

// Redux: Root Reducer
const rootReducer = combineReducers({
  authReducer: userAuthReduxTypes.reducer,
  userReducer: userReduxTypes.reducer,
  roleReducer: roleReduxTypes.reducer,
  licensingReducer: licensingReduxTypes.reducer,
  fosterFamilyInfoReducer: fosterFamilyInfoReduxTypes.reducer,
  currentPlacementsReducer: currentPlacementReduxTypes.reducer,
  fileUploadDownloadReducer: fileUploadDownloadReduxTypes.reducer,
  //routing: routerReducer,
  oidc: oidcReducer,
});

// Exports
export default rootReducer;