import  React from 'react'
import {ActivityIndicator} from 'react-native'
// import {Overlay} from 'react-native-elements'
import { View, Text, List, ListItem } from 'native-base'
import { NavigationActions, withNavigationFocus } from 'react-navigation';
import { ScrollView } from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as apiHelper from "../../common/apiHelper";
import {connect} from 'react-redux'
import {userReduxTypes} from './../../../redux/users/user.redux'
import FFPAlert from '../../common/Alert/alert';
import WebHeaderTitle from '../../common/webHeaderTitle';

class UserListScreen extends React.Component {
    constructor(props) {
        super(props);

        this.addNewUser = this.addNewUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteClickConfirm = this.deleteClickConfirm.bind(this);
        this.cancelDeleteClick = this.cancelDeleteClick.bind(this);

        this.state = { deleteUserClicked: false, deleteUserDetails: undefined}
    }
    componentWillMount() {
      this.refreshList();
    }

    componentDidUpdate(prevProps) {
      if (this.props.isFocused && !prevProps.isFocused) {
        // Screen has now come into focus, perform your tasks here! 
        this.refreshList();
      }
    }
  

    componentWillUnmount() {
      this.setState({deleteUserClicked: false, deleteUserDetails: undefined})
    }

    addNewUser() {
        this.props.navigation.navigate('Add')
    }

    cancelDeleteClick() {
      this.setState({deleteUserClicked: false, deleteUserDetails: undefined})
    }

    deleteClickConfirm() {

      this.props.deleteUser(this.state.deleteUserDetails, () => {
        this.setState({deleteUserClicked: false, deleteUserDetails: undefined})
        this.refreshList()
      });
    }

    refreshList() {
      this.props.getAllUsers((resp) => {
        console.log(resp)
      });
    }

    deleteUser(user) {
        // this.props.navigation.navigate('Delete', {user: user})
        this.setState({deleteUserClicked: true, deleteUserDetails: user});
    }

    editUser(user) {
        this.props.navigation.navigate({routeName: 'Edit', params: {user}})
    }

    render() {
      console.log(this.props.navigation.state)
        return (
          <View>
            <ScrollView>
              {this.state.deleteUserClicked && <FFPAlert title="Delete User" desc="Are you Sure?" 
                    onPositivePress={this.deleteClickConfirm} 
                    onNegativePress={this.cancelDeleteClick} 
                />}
                <WebHeaderTitle text='Users List'></WebHeaderTitle>
                <View style={{flexDirection: 'row-reverse'}}>
                  <Icon name="plus" size={40} style={{textAlign: 'right', paddingRight: 10, width: 50}}
                      onPress={this.addNewUser} />
                  <Icon name="refresh" size={40} style={{textAlign: 'right', width: 50, paddingRight: 10}}
                      onPress={this.refreshList} />
                </View>
            <List>
            
        <ActivityIndicator animating={this.props.isFetchingAllUsers} size="large" color="#0000ff" /> 
        
                {this.props.allUsers && 
                this.props.allUsers.map((user, ind) => {
                    return ( <ListItem key = {ind}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>{user.firstName + ' ' + user.lastName}</Text>
                        <Icon name="pencil" size={30} style={{height: 30, width: 30}} 
                            onPress={() => this.editUser(user)} />
                        <Icon name="trash" size={30} style={{height: 30, width: 30}}
                            onPress={() => this.deleteUser(user)} />
                        </View>
                      </ListItem>)
                })}
          </List>
            </ScrollView>
            </View>
        )
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  console.log(userReduxTypes)
  // Redux Store --> Component
  return {
    allUsers: userReduxTypes.selectors.getAllUsers(state),
    // rawState: state,
    isFetchingAllUsers: userReduxTypes.selectors.getIsFetchingAllUsers(state),
    errorFetchingAllUsers: userReduxTypes.selectors.getErrorFetchingAllUsers(state),
    isDeleteUserInProgress: userReduxTypes.selectors.getIsDeleteUserInProgress(state),
    errorDeletingUser: userReduxTypes.selectors.getErrorDeletingUser(state),
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
    return {
      // Login
      getAllUsers: (callback) => userReduxTypes.actions.fetchAllUsers(dispatch, {}, callback),
      deleteUser: (userDetails, callback) => userReduxTypes.actions.deleteUser(dispatch, userDetails, callback),
   };
};

export default withNavigationFocus(connect(mapStateToProps, mapDispatchToProps)(UserListScreen))