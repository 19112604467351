import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: 'ffp-authCode-workflow',
  client_secret: 'secret',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/Auth/OidcCallback`,
  response_type: 'code',
  scope: 'openid profile email FFP_API offline_access',
  authority: 'https://identityserver.customlocalstore.dev.fostercaretech.com/',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
 