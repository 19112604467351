import React from "react";
import { StyleSheet } from "react-native";
import {
  View,
  Button
} from "native-base";
import { Modal } from 'react-bootstrap';

class FFPAlert extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      settings: {
        title: props.title, desc: props.desc, buttons: [{
          text: "Ok",
          onPress: () => {
            props.onPositivePress()
          }
        }, {
          text: "Cancel",
          onPress: () => {
            props.onNegativePress()
          }
        }]
      }
    };
  }

  handleClose() {
    this.props.onNegativePress()
  }

  onSelect(f) {
    // this.setState({settings: null});
    f();
  }
  render() {
    const settings = this.state.settings
    return (
      settings && (
        <View style={styles.wrapper}>

          <Modal show={true} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{settings.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{settings.desc}</Modal.Body>
            <Modal.Footer>
              {settings.buttons.map((button, ind) => (
                <Button key={ind}
                  disabled={button.disabled}
                  onPress={() => this.onSelect(button.onPress)}
                  style={styles.card}
                >
                  {button.text}
                </Button>
              ))}
            </Modal.Footer>
          </Modal>
        </View>
      )
    );
  }
}

export default FFPAlert;

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    //zIndex: 10
  },
  cards: {
    minWidth: 300,
    justifyContent: "flex-end",
    flexDirection: "row"
  },
  card: {
    marginLeft: 5,
    paddingLeft: 5,
    paddingRight: 5
  }
});