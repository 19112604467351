import React from "react";
import { View, Text } from "native-base";

export default class RenewalScreen extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <View><Text>Renewal Screen</Text></View>
        );
    }
}