import { StyleSheet } from 'react-native';

var styles = StyleSheet.create({
  container: {
    // justifyContent: 'center',
    marginTop: 10,
    padding: 20,
    backgroundColor: '#ffffff',
    // overflow: 'scroll',
    // height: '100vh'
  },
  buttonText: {
    fontSize: 18,
    color: 'white',
    alignSelf: 'center'
  },
  button: {
    height: 36,
    backgroundColor: 'teal',
    borderColor: 'teal',
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 10,
    alignSelf: 'stretch',
    justifyContent: 'center'
  }, button1: {
    borderRadius: 5,
    padding: 5,
    fontSize: 17,
    fontWeight: "bold",
    color: "white",
    backgroundColor: "teal",
    maxWidth: 400
  }
});
export default styles;