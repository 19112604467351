import React from 'react';
import { userAuthReduxTypes } from '../../../redux/users/auth.redux';
import { connect } from 'react-redux';
import { Button } from 'native-base';
import { View, KeyboardAvoidingView, TouchableHighlight, SafeAreaView, ActivityIndicator, Text, Image, Platform, Linking, ScrollView, Dimensions } from 'react-native'
import { NavigationActions } from "react-navigation";
import * as AsyncStorage from "../../common/AsyncStorage/storageHelper";
import ValidationComponent from 'react-native-form-validator';
import t from 'tcomb-form-native'
import styles from './../../Admin/styles'
import * as generalHelper from '../../common/generalHelper'
import { TouchableOpacity } from 'react-native-gesture-handler';
import userManager from "../oidc/userManager";

const Form = t.form.Form;

var Login = t.struct({
  userName: t.String,
  password: t.String,
});

var options = {
  fields: {
    userName: {
      error: "User name is required",
      i18n: {
        required: ''
      },
    },
    password: {
      password: true,
      secureTextEntry: true,
      error: "Password is required",
      i18n: {
        required: ''
      },
    }
  }
}

class LoginScreen extends ValidationComponent {
  constructor(props) {
    super(props);
    var deviceWidth = Dimensions.get("window").width;
    var deviceHeight = Dimensions.get("window").height;

    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.gotoGuestScreen = this.gotoGuestScreen.bind(this);

    this.state = { userName: '', password: '', isMobile: false, deviceHeight: deviceHeight, deviceWidth: deviceWidth }
  }

  static navigationOptions = {
    // header: null,
    headerTitle: "Login"
  }
  detectMobileBrowser() {
    var check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    this.setState({ isMobile: check })
  }
  async componentDidMount() {
    if (Platform.OS == "web")
      this.detectMobileBrowser()

    // if(!this.props.user || !this.props.user.user) {
    //   //userManager.signinRedirect();
    //    userManager.signinRedirect()
    // }
    // else{
    //   this.props.navigation.navigate('Home')
    // }
  }



  login() {
    var userDetails = this.refs.form.getValue();
    if (userDetails) { // if validation fails, value will be null
      this.props.login(userDetails, async (resp) => {
        resp.data.email = userDetails.userName;
        await AsyncStorage.setItem('loggedInUserDetails', JSON.stringify(resp.data))
        if (resp.data.shouldChangePassword) {
          this.props.navigation.navigate('ChangePassword')
        }
        else {
          if (resp.data.isGuestUser) {
            this.props.navigation.navigate('Home', {}, NavigationActions.navigate({ routeName: 'Guest' }));

          }
          else {
            this.props.navigation.navigate('Home')
          }
        }
      });
    }
  }

  forgotPassword() {
    this.props.navigation.navigate('ForgotPassword')
  }

  gotoGuestScreen() {
    this.props.navigation.navigate('Guest')
  }

  render() {
    return (

      <KeyboardAvoidingView style={styles.container} behavior={"position"} keyboardVerticalOffset={-90}>
        <SafeAreaView style={{ height: this.state.deviceHeight - 80 }}>
          <ScrollView>
            {!this.state.isMobile && <View>
              <Image
                source={require('../../common/assets/logo.png')
                }
                style={{ width: 330, height: 175, alignSelf: 'center' }}
              />
              
              <Form
                ref="form"
                type={Login}
                options={options}
                value={this.state.value}
                onChange={(value) => generalHelper.onFormChange(value, this)}
              />
              <TouchableHighlight style={styles.button} onPress={this.login}
                underlayColor='#99d9f4' >
                <Text style={styles.buttonText}>
                  Login
          </Text>
              </TouchableHighlight>
              {this.props.errorFetchingUserDetails &&
                <Text style={{ color: 'red' }}>
                  {generalHelper.getErrorMessageFromErrorResp(this.props.errorFetchingUserDetails, 'Error Logging in. Please contact administrator.')}
                </Text>}

              <View style={{ paddingTop: 10, justifyContent: "space-between", flexDirection: "row" }}>
                <TouchableHighlight onPress={this.forgotPassword}>
                  <Text style={{color: "#006EA5", fontWeight: "bold"}}> Forgot Password? </Text>
                </TouchableHighlight>

              </View>
            </View>}
            {Platform.OS == "web" &&
              <View style={{ paddingBottom: 10, alignContent: 'center', alignSelf: 'center' }}>
                <Text style={{ fontSize: 18 }}>Login above or download our mobile app for your mobile devices:</Text>
                <View style={{paddingTop: 20}}><Text style={{ fontSize: 18 }}>Please contact <a href="mailto:tfi-ffpsupport@tfifamily.org">tfi-ffpsupport@tfifamily.org</a> if you need assistance or support with this portal.</Text></View>
                <Image
                  source={require('../../common/assets/tfi-ffp-app-link-logo.png')
                  }
                  style={{ width: 80, height: 80, margin: 5, alignSelf: 'center' }}
                />
                <TouchableOpacity style={{ width: 200, alignSelf: 'center' }} onPressOut={() => Linking.openURL('https://apps.apple.com/us/app/id1519723790')}>
                  <Image source={require('../../common/assets/apple-app-store.png')
                  } style={{ width: 200, height: 60, alignSelf: 'center' }} />
                </TouchableOpacity>
                <TouchableOpacity style={{ width: 180, alignSelf: 'center' }} onPressOut={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.fosterfamilyportal')}>
                  <Image source={require('../../common/assets/get-it-on-google-play.png')
                  } style={{ width: 235, height: 85, alignSelf: 'center' }} />
                </TouchableOpacity>
              </View>

            }
          </ScrollView>
        </SafeAreaView>
      </KeyboardAvoidingView>

    );
  }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  console.log(state)
  // Redux Store --> Component
  return {
    loggedIn: userAuthReduxTypes.selectors.getAuthDetails(state),
    isFetchingUserDetails: userAuthReduxTypes.selectors.getIsFetchingUserDetails(state),
    errorFetchingUserDetails: userAuthReduxTypes.selectors.getErrorFetchingUserDetails(state),
    user: state.oidc,
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
  return {
    // Login
    login: (loginDetails, callback) => userAuthReduxTypes.actions.fetchUserDetails(dispatch, loginDetails, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
