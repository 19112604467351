import React from 'react';
import { View, Text, Button } from 'native-base';

export default class DeleteUserScreen extends React.Component {
    constructor(props) {
        super(props);
    }

    goBack() {
        this.props.navigation.goBack()
    }

    render() {
        return (
            <View>
                <Text>Delete Screen</Text>
                <Button onPress={() => this.goBack()}>GO Back</Button>
            </View>
        )
    }
}