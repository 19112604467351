import React from 'react';
import { Button } from 'native-base';
import { TouchableHighlight, Text, Platform } from "react-native";
import t from 'tcomb-form-native'
import styles from '../styles'
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import * as generalHelper from './../../common/generalHelper'
import { userReduxTypes } from "../../../redux/users/user.redux";
import { ActivityIndicator } from 'react-native-paper';
import WebHeaderTitle from '../../common/webHeaderTitle';
import * as apiHelper from './../../common/apiHelper'

const Form = t.form.Form;

var EditUser = t.struct({
  firstName: t.maybe(t.String),
  lastName: t.maybe(t.String),
  loginName: t.String,
  userUniqueCode: t.String,
  email: t.String,
  state: t.String,
  dateOfBirth: t.Date, // a date field,
  phoneNumber: t.maybe(t.String),  // an optional string
  optForEmail: t.Boolean,        // a boolean
  optForMobile: t.Boolean
});

var options = {
  fields: {
    dateOfBirth: {
      mode: 'date',
      config: {
        format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
        defaultValueText: generalHelper.tCombDateFormat(Platform.OS, new Date())
      },
    },
    state: {
      options: [],
      factory: t.form.Select,
      nullOption: { value: '', text: 'Choose your State' }
    }
  }
};

class EditUserScreen extends React.Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);

    const userToEdit = this.props.navigation.getParam('user')

    if (!userToEdit || generalHelper.isDummyObject(userToEdit)) {

      // const listAction = StackActions.reset({
      //     index: 0,
      //     actions: [NavigationActions.navigate({ routeName: 'List' })],
      //   });
      //   this.props.navigation.dispatch(listAction);

      this.props.navigation.setParams({ user: null })
      this.props.navigation.navigate('List', { user: null })
      this.state = { userToEdit: {} }
    }
    else {
      userToEdit.dateOfBirth = new Date(userToEdit.dateOfBirth)
      this.state = { userToEdit, options: options }
      this.onEdit = this.onEdit.bind(this);
    }

  }

  componentDidMount() {
    apiHelper.get('/state').then((resp) => {
      var updatedOptions = { ...options };
      updatedOptions.fields.state.options = resp.data.map(x => { return { text: x.stateName, value: x.stateId } });
      this.setState({ options: updatedOptions })
    })
  }

  goBack() {
    this.props.navigation.goBack()
  }

  onEdit() {
    // call getValue() to get the values of the form
    var value = this.refs.form.getValue();

    const userId = this.state.userToEdit.userId;

    value = { ...value, userId }
    if (value) { // if validation fails, value will be null
      console.log(value); // value here is an instance of Person
      this.props.saveUser(value, (resp) => {
        this.goBack();
      })
    }
  }

  render() {
    return (
      <ScrollView style={styles.container}>
        <WebHeaderTitle>Edit User</WebHeaderTitle>
        <Button onPress={this.goBack} title="Back">Back</Button>
        {this.state.options && <Form
          ref="form"
          type={EditUser}
          options={this.state.options}
          value={this.state.userToEdit}
        />}
        <TouchableHighlight style={styles.button} onPress={this.onEdit}
          underlayColor='#99d9f4' disabled={this.props.isSaveUserInProgress}>
          <Text style={styles.buttonText}>
            Edit {this.props.isSaveUserInProgress && <ActivityIndicator />}
          </Text>
        </TouchableHighlight>
        {this.props.errorSavingUser &&
          <Text style={{ color: 'red' }}>
            {generalHelper.getErrorMessageFromErrorResp(this.props.errorSavingUser, 'Error Editing user. Please contact administrator.')}
          </Text>}
      </ScrollView>
    )
  }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
  console.log(state)
  // Redux Store --> Component
  return {
    isSaveUserInProgress: userReduxTypes.selectors.getIsSaveUserInProgress(state),
    errorSavingUser: userReduxTypes.selectors.getErrorSavingUser(state),
  };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
  // Action
  return {
    // Login
    saveUser: (userDetailsToSave, callback) => userReduxTypes.actions.saveUser(dispatch, userDetailsToSave, callback),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserScreen)
