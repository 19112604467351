import React from 'react';
import { View, ActivityIndicator, StatusBar, Linking } from 'react-native';
import styles from '../../Styles/containerStyle'
import * as AsyncStorage from '../common/AsyncStorage/storageHelper'
import * as generalHelper from '../common/generalHelper'
import { NavigationActions } from "react-navigation";
import userManager from "./oidc/userManager";

export class AuthLoadingScreen extends React.Component {
  constructor() {
    super();
    this._bootstrapAsync();
  }

  // Fetch the token from storage then navigate to our appropriate place
  _bootstrapAsync = async () => {
    var userDetails = await AsyncStorage.getItem('loggedInUserDetails');
    if (userDetails && generalHelper.isJsonString(userDetails)) {
      userDetails = JSON.parse(userDetails)
    }
    else {
      userDetails = {};
    }

    Linking.addEventListener('url', () => { alert() });

    const userToken = userDetails && userDetails.token ? userDetails.token : undefined;
    const hasChangedPassword = userDetails ? userDetails.shouldChangePassword : undefined;
    const isGuestUser = userDetails ? userDetails.isGuestUser : undefined;

    // if (window.location.href.indexOf('app/Signin') > -1) {
    //   this.props.navigation.navigate('SignIn');
    //   return;
    // }
    

    if (!userToken) {
      //this.props.navigation.navigate('Auth');
      userManager.signinRedirect().then(user => {
        alert(user)
      })
      return;
    }
    else if (hasChangedPassword) {
      this.props.navigation.navigate('ChangePassword');
      return;
    }
    else if (isGuestUser) {
      this.props.navigation.navigate('Home', {}, NavigationActions.navigate({ routeName: 'Guest' }));
      return;
    }
    else {
      // this.props.navigation.navigate('Home');
    }
    // This will switch to the App screen or Auth screen and this loading
    // screen will be unmounted and thrown away.
    this.props.navigation.navigate(userToken ? 'Home' : 'Auth');
    // this.props.navigation.goBack();
  };

  // Render any loading content that you like here
  render() {
    return (
      <View style={styles.container}>
        <ActivityIndicator style={{ height: 100 }} />
        <StatusBar barStyle="default" />
      </View>
    );
  }
}