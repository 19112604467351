import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './redux/store/store';
import { Provider } from 'react-redux'
import { Platform, View } from 'react-native'
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserApp } from "@react-navigation/web";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavHeader from './features/common/Header/header';
import { OidcProvider } from 'redux-oidc';
import userManager from './features/Authentication/oidc/userManager';

const WebApp = createBrowserApp(App)

const AppLayout = () => {
    return (
        <View style={{ height: '100vh', width: '100%' }}>

            {/* <AppNavHeader /> */}
            <OidcProvider store={store} userManager={userManager}>
                <WebApp />
            </OidcProvider>
        </View>
    )
}
// const WebApp = createAppContainer(App)
const WebAppWithStore = () => {
    return (
        <Provider store={store}>
                {Platform.OS === 'web' ? (
                    <style type="text/css">{`
                    @font-face {
                        font-family: 'FontAwesome';
                        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
                    }
                    `}</style>
                ) : null}
                <PersistGate loading={null} persistor={persistor}>
                    <AppLayout />
                </PersistGate>
        </Provider>

    )
}
ReactDOM.render(<WebAppWithStore />, document.getElementById('root'));
// ReactDOM.render(<WebApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
