import React from 'react';
import { TouchableHighlight, Text, Button, Platform} from 'react-native'
import { View, Header } from 'native-base';
import t from 'tcomb-form-native'
import styles from '../styles'
import { ScrollView } from 'react-native-gesture-handler';
import { connect } from 'react-redux';
import { userReduxTypes } from "../../../redux/users/user.redux";
import { ActivityIndicator } from 'react-native-paper';
import WebHeaderTitle from '../../common/webHeaderTitle';
import * as generalHelper from '../../common/generalHelper'

import * as apiHelper from './../../common/apiHelper'

const Form = t.form.Form;

  var AddUser = t.struct({
    firstName: t.maybe(t.String),
    lastName: t.maybe(t.String),
    loginName: t.String, 
    familyCode: t.String,
    email: t.String,
    state: t.String,
    dateOfBirth: t.Date, // a date field,
    phoneNumber: t.maybe(t.String),  // an optional string
    optForEmail: t.Boolean,        // a boolean
    optForMobile: t.Boolean
  });
  
  var options = {
    fields: {
      dateOfBirth: {
        mode: 'date',
        config: {
          format: (date) => generalHelper.tCombDateFormat(Platform.OS, date),
          defaultValueText: generalHelper.tCombDateFormat(Platform.OS, new Date())
        },
      },
      state: {
        options: [],
        factory: t.form.Select,
        nullOption: {value: '', text: 'Choose your State'}
      }
    }
  };
  
class AddUserScreen extends React.Component {

    
    constructor(props) {
      super(props);
  
      this.onAdd = this.onAdd.bind(this);
      this.goBack = this.goBack.bind(this);

      this.state = { options: undefined }
    }

    componentWillMount() {
      apiHelper.get('/state').then((resp) => {
        var updatedOptions = {...options};
        updatedOptions.fields.state.options = resp.data.map(x => {return {text: x.stateName, value: x.stateId}});
        this.setState({options: updatedOptions})
      })
    }

    goBack() {
        this.props.navigation.goBack()
    }

    onAdd() {
        // call getValue() to get the values of the form
        var value = this.refs.form.getValue();
        if (value) { // if validation fails, value will be null
          console.log(value); // value here is an instance of Person
          this.props.saveUser(value, (resp) => {
            this.goBack();
          })
        }
      }

    render() {
        return (
            <ScrollView style={styles.container}>
              <WebHeaderTitle>Add User</WebHeaderTitle>
              <Button onPress={this.goBack} title="Back">BAck</Button>
              {this.state.options && <Form
                    ref="form"
                    type={AddUser}
                    options={this.state.options}
                    value={this.state.value}
                    onChange={(value) => generalHelper.onFormChange(value, this)}
    /> }
              <TouchableHighlight style={styles.button} onPress={this.onAdd} 
                underlayColor='#99d9f4' disabled={this.props.isSaveUserInProgress}>
                <Text style={styles.buttonText}>
                  Add {this.props.isSaveUserInProgress && <ActivityIndicator /> }
                </Text>
              </TouchableHighlight>
              {this.props.errorSavingUser && 
                <Text style={{color: 'red'}}>
                  {generalHelper.getErrorMessageFromErrorResp(this.props.errorSavingUser, 'Error Adding user. Please contact administrator.')}
                </Text> }
            </ScrollView>
        )
    }
}



// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    // Redux Store --> Component
    return {
      isSaveUserInProgress: userReduxTypes.selectors.getIsSaveUserInProgress(state),
      errorSavingUser: userReduxTypes.selectors.getErrorSavingUser(state),
    };
  };
  
  // Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
  const mapDispatchToProps = (dispatch) => {
      return {
        saveUser: (userDetailsToSave, callback) => userReduxTypes.actions.saveUser(dispatch, userDetailsToSave, callback),
     };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AddUserScreen)