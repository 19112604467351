import React from 'react';
import { View, Text, Image } from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';

export default class QuestionsScreen extends React.Component {
    
    static navigationOptions = {
        drawerLabel: 'Questions',
            drawerIcon: ({ tintColor }) => (
              <Icon
                name="home"
                size={30}
                iconStyle={{
                  width: 30,
                  height: 30,
                }}
                type="material"
                color={tintColor}
              />
            ),
          
      };    

    render() {
        return (<View><Text>Some thing</Text></View>)
    }
}