import React from 'react';
import { View, Text, ActivityIndicator, ScrollView, SafeAreaView, Dimensions, Platform, TouchableOpacity, Image } from "react-native";
import { Button } from "native-base"
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import FFPModal from './../common/Modal/modal'
import FFPFileUpload from "../common/FileUpload/FileUpload";
import FFPAlert from '../common/Alert/alert'
import axiosInst from './../common/axiosHelper'
import * as configHelper from "./../common/configHelper";
import Icon from 'react-native-vector-icons/FontAwesome';
import styles from './AppStyles';
import * as apiHelper from '../common/apiHelper'
//import messaging from '@react-native-firebase/messaging';
import * as AsyncStorage from '../common/AsyncStorage/storageHelper';
const apiBaseUrl = configHelper.getConfigValue('apiBaseUrl');

class NotificationScreen extends React.Component {
    constructor(props) {
        super(props);
        //this.openClientDetails = this.openClientDetails.bind(this);
        //this.updateInterest = this.updateInterest.bind(this);
        var deviceWidth = Dimensions.get("window").width;
        var deviceHeight = Dimensions.get("window").height;
        this.state = {
            notifications: "No notifications",
            newNotifications: undefined,
            archivedNotifications: undefined,
            isFetchingNotifications: true,
            isArchive: false,
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight,
            archivePressed: false

        };
    }
    componentDidMount() {
        this.getNotifications()
        //console.log("TOKEN")
        //messaging().getToken().then(token => { console.log(token) });
    }
    componentDidUpdate() {

    }
    openArchive() {
        this.setState({ isArchive: !this.state.isArchive, archivePressed: false });
    }
    filterArchive() {
        let archivedNotifications = [];
        let newNotifications = [];
        for (let i = 0; i < this.state.notifications.length; i++) {
            if (this.state.notifications[i].isArchived)
                archivedNotifications.push(this.state.notifications[i])
            else
                newNotifications.push(this.state.notifications[i])
        }
        this.setState({ archivedNotifications: archivedNotifications, newNotifications: newNotifications, isFetchingNotifications: false })
    }
    async markArchive(notificationId) {
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\",\n\"notificationId\":" + notificationId + "	\n}";

        xhr.addEventListener("readystatechange", () => {
            if (xhr.readyState === 4) {
                //console.log(xhr.response);
                //let response = JSON.parse(xhr.response);
                //this.setState({ archivedSuccess: response });
                this.getNotifications();
                this.setState({ notificationId: undefined, archivePressed: false })
                //this.getInterest(JSON.parse(xhr.response))
                //console.log(response);

            }
        });

        xhr.open("POST", `${apiBaseUrl}/notifications/archive`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Authorization", "Bearer " + this.props.userDetails.token);
        xhr.send(data);
        //console.log(data);
    }
    async getNotifications() {
        let data = "{\n	\"UserUniqueCode\": \"" + this.props.userDetails.userUniqueCode + "\"\n	\n}";
        var res = await apiHelper.post('/notifications/search', data)
        this.setState({ notifications: res.data.notifications });
        this.filterArchive();
    }
    render() {
        return (
            <View style={this.state.isArchive ? { backgroundColor: "#FEFCF3" } : {}}>
                <SafeAreaView style={Platform.OS == "web" ? { height: this.state.deviceHeight - 55, marginBottom: 0 } : { height: this.state.deviceHeight - 75 }}>
                    <ScrollView>
                        {!this.state.isArchive && <View style={{ alignItems: 'center' }}>
                            <Text style={styles.h1}>Welcome to</Text>
                            <Image style={{ width: 200, height: 100 }} source={require('../common/assets/logo.png')
                            } />
                            <Text style={styles.h3}>Collaborate with your licensing agency to manage your fostering experience</Text>
                        </View>}
                        <View style={styles.h1v}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text style={styles.h1}>{!this.state.isArchive ? "Notifications" : Platform.OS == "web" ? "Notifications Archive" : "Notifications"}</Text>
                                    {this.state.isArchive && Platform.OS != "web" && <Text style={{
                                        borderStyle: 'solid',
                                        borderColor: '#008080',
                                        fontSize: 30,
                                        fontWeight: 'bold',
                                        flexWrap: 'wrap'
                                    }}>Archive</Text>}
                                </View>
                                <TouchableOpacity onPress={() => this.openArchive()} style={{ padding: 2, alignSelf: 'flex-end', flexWrap: 'wrap' }}>
                                    <Text style={styles.button1}>{!this.state.isArchive ? "To Notifications Archive" : "To New Notifications"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <ScrollView>
                            <View style={{ flex: 1, padding: 10, alignItems: 'center' }}>
                                {this.state.archivePressed &&
                                    <FFPAlert title="Archive notification" desc="Are you sure you want to archive this notification?"
                                        onPositivePress={() => this.markArchive(this.state.notificationId)}
                                        onNegativePress={() => this.setState({ archivePressed: false })} />
                                }
                                {this.state.isFetchingNotifications ? <ActivityIndicator /> : !this.state.notifications.length ? <Text>No notifications</Text> : !this.state.isArchive ?
                                    this.state.newNotifications.map((notification, notificationID) => {
                                        return (
                                            <View key={notificationID} style={{
                                                backgroundColor: "#FEFCF3", borderWidth: 2, borderColor: "#008080",
                                                borderRadius: 5, padding: 2, margin: 2, maxWidth: 700, minWidth: 500, minHeight: 175, maxHeight: 200, flex: 1,
                                            }}>
                                                <View style={{ borderRadius: 5, padding: 1, flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>

                                                    <View style={{ flex: 1 }}>

                                                        <Text>
                                                            From: {notification.senderName}
                                                        </Text>
                                                        <Text>
                                                             {notification.startDate} {notification.startTime}
                                                        </Text>

                                                    </View>
                                                    <View style={{ flexDirection: 'row', padding: 5, width: 160, justifyContent: 'flex-end' }}><Text >
                                                        {notification.notificationCategoryDescription}</Text></View>
                                                    <View >

                                                        <TouchableOpacity onPressIn={() => this.setState({ archivePressed: true, notificationId: notification.notificationId })}>
                                                            <Text style={styles.button1}>Archive</Text>
                                                        </TouchableOpacity>

                                                    </View>
                                                </View>
                                                <View style={{ flex: 1, maxWidth: 395, paddingBottom: 20 }}>
                                                    <Text style={{ padding: 1 }}>{notification.notes}</Text>
                                                </View>

                                            </View>
                                        )
                                    }) : this.state.archivedNotifications.map((notification, notificationID) => {
                                        return (
                                            <View key={notificationID} style={{
                                                backgroundColor: "#FEFCF3", borderWidth: 2, borderColor: "#008080",
                                                borderRadius: 5, padding: 2, margin: 2, maxWidth: 700, minWidth: 500, minHeight: 175, maxHeight: 200, flex: 1
                                            }}>
                                                <View style={{ borderRadius: 5, padding: 1, flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
                                                    <View style={{ flex: 1 }}>

                                                        <Text>
                                                            From: {notification.senderName}
                                                        </Text>
                                                        <Text>
                                                             {notification.startDate} {notification.startTime}
                                                        </Text>

                                                    </View>
                                                    <View style={{ flexDirection: 'row', padding: 5, width: 160, justifyContent: 'flex-end' }}><Text >
                                                        {notification.notificationCategoryDescription}</Text></View>
                                                </View>
                                                <View style={{ flex: 1, maxWidth: 395, paddingBottom: 20 }}>
                                                    <Text style={{ padding: 1 }}>{notification.notes}</Text>
                                                </View>

                                            </View>
                                        )
                                    })

                                }
                            </View>
                        </ScrollView >
                    </ScrollView>
                </SafeAreaView>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
    };
};
export default connect(mapStateToProps)(NotificationScreen)