import React from "react";
import { View, Text } from "native-base";
import { ScrollView, SafeAreaView, Dimensions, Platform, ActivityIndicator, StyleSheet, Linking } from 'react-native';
import { fosterFamilyInfoReduxTypes } from './../../redux/users/fosterfamilyinfo.redux';
import { userAuthReduxTypes } from './../../redux/users/auth.redux'
import { connect } from 'react-redux';
import { Table, TableWrapper, Row, Rows, Col, Cols, Cell } from 'react-native-table-component';
import commonStyles from "./../../Styles/commonStyles";

class FosterParentInfoScreen extends React.Component {
    constructor(props) {
        super(props);

        var deviceWidth = Dimensions.get("window").width;
        var deviceHeight = Dimensions.get("window").height;

        this.state = {
            deviceWidth: deviceWidth,
            deviceHeight: deviceHeight,
        };
        this.props.fetchFosterFamilyInfoDetails({ stateCode: this.props.userDetails.stateCode });
    }



    render() {
        var externalLinks = [], formattedEmergencyContacts = [], formattedOtherContacts = [];
        if (!this.props.isFetchingFosterFamilyInfoDetails && this.props.fosterFamilyInfoDetails) {
            if (this.props.fosterFamilyInfoDetails.contacts && this.props.fosterFamilyInfoDetails.contacts.length) {
                formattedEmergencyContacts = this.props.fosterFamilyInfoDetails.contacts.filter(x => x.contactTypeCode == "EC").map(x => [x.name, x.value]);
                formattedOtherContacts = this.props.fosterFamilyInfoDetails.contacts.filter(x => x.contactTypeCode == "OC").map(x => [x.name, x.value]);
            }
            if (this.props.fosterFamilyInfoDetails.externalLinks && this.props.fosterFamilyInfoDetails.externalLinks.length) {
                externalLinks = this.props.fosterFamilyInfoDetails.externalLinks;
            }
        }

        return (

            <SafeAreaView style={Platform.OS == "web" ? { height: "90vh", marginBottom: 0 } : {}}>
                {Platform.OS == "web" && <View style={commonStyles.paddingTopBottom}><Text style={[commonStyles.h1, commonStyles.topPadding10]}>Foster Family Information</Text></View>}
                <ScrollView showsVerticalScrollIndicator={false} style={[styles.paddingBottom, { height: '90%' }]}>

                    <View style={styles.paddingTopBottom}>
                        <Text style={styles.head}>Emergency Contacts</Text>


                        {this.props.isFetchingFosterFamilyInfoDetails
                            ? (<ActivityIndicator />)
                            : formattedEmergencyContacts && formattedEmergencyContacts.length > 0
                                ? (<View><Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
                                    {/* <Row data={x.name} style={styles.head} textStyle={styles.text}/> */}
                                    <Rows data={formattedEmergencyContacts} textStyle={styles.text} />
                                </Table></View>)
                                : <View><Text>There are no emergency contacts</Text></View>}
                    </View>

                    <View style={styles.paddingTopBottom}>
                        <Text style={styles.head}>Helpful Links</Text>


                        {this.props.isFetchingFosterFamilyInfoDetails
                            ? (<ActivityIndicator />)
                            : externalLinks && externalLinks.length > 0
                                ? <View style={{ margin: 'auto' }}>{externalLinks.map((linkDet, linkKey) => (
                                    <View key={linkKey} style={styles.linkStyle}>
                                        <Text style={styles.linkText}
                                            accessibilityRole="link"
                                            href={linkDet.url}
                                            style={styles.text}
                                            target="_blank"
                                            onPress={() => Platform.OS == "web" ? undefined : Linking.openURL(linkDet.url)}>{linkDet.displayName}</Text>
                                    </View>))}</View>
                                : <Text>There are no Links</Text>}

                    </View>

                    <View style={styles.paddingTopBottom}>
                        <Text style={styles.head}>Other Contacts</Text>


                        {this.props.isFetchingFosterFamilyInfoDetails
                            ? (<ActivityIndicator />)
                            : formattedOtherContacts && formattedOtherContacts.length > 0
                                ? (<Table borderStyle={{ borderWidth: 2, borderColor: '#c8e1ff' }}>
                                    <Rows data={formattedOtherContacts} textStyle={styles.text} />
                                </Table>)
                                : <View><Text>There are no Other contacts</Text></View>}
                    </View>
                </ScrollView>
            </SafeAreaView>
        );
    }
}

// Map State To Props (Redux Store Passes State To Component)
const mapStateToProps = (state) => {
    //console.log(state)
    // Redux Store --> Component
    return {
        fosterFamilyInfoDetails: fosterFamilyInfoReduxTypes.selectors.getFosterFamilyInfoDetails(state),
        isFetchingFosterFamilyInfoDetails: fosterFamilyInfoReduxTypes.selectors.getIsFetchingFosterFamilyInfoDetails(state),
        errorFetchingFosterFamilyInfoDetails: fosterFamilyInfoReduxTypes.selectors.getErrorFetchingFosterFamilyInfoDetails(state),
        userDetails: userAuthReduxTypes.selectors.getAuthDetails(state)
    };
};

// Map Dispatch To Props (Dispatch Actions To Reducers. Reducers Then Modify The Data And Assign It To Your Props)
const mapDispatchToProps = (dispatch) => {
    // Action
    return {
        fetchFosterFamilyInfoDetails: (state, callback) => fosterFamilyInfoReduxTypes.actions.fetchFosterFamilyInfoDetails(dispatch, state, callback),
    };
};

FosterParentInfoScreen.defaultProps = {
    userDetails: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(FosterParentInfoScreen)

var normalTextFontSize = Platform.OS == "web" ? 20 : Platform.OS == "android" ? 10 : 20;
var paddingSize = Platform.OS == "web" ? 20 : 10
var mainHeadingFontSize = Platform.OS == "web" ? 35 : 20
var headingFontSize = Platform.OS == "web" ? 30 : Platform.OS == "android" ? 18 : 25;
const styles = StyleSheet.create({
    container: { paddingTop: 30, fontSize: normalTextFontSize },
    head: { margin: 'auto', fontSize: headingFontSize, padding: 10 },

    text: { margin: 6, fontSize: normalTextFontSize },
    linkText: { margin: 6, fontSize: normalTextFontSize, color: 'blue', textAlign: 'center' },
    linkStyle: { margin: 10, backgroundColor: 'rgba(241, 238, 167, 1)', borderRadius: 20 },
    paddingTopBottom: { paddingBottom: paddingSize, paddingTop: paddingSize }
});
